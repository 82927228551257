import { API_ENDPOINT_GAUTH_LOGIN } from "app/scenes/auth/auth.constants";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigation } from "./app/utils/NavigationContext";
import { SAVE_USER_INFO } from "../src/app/scenes/auth/redux/auth.action";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";

const SocialAuth = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigation();
  const [loading, setLoading] = useState(true);
  const token = Cookies.get("USER_TOKEN");
  console.log("token availible", token);

  const handleGetUserData = async () => {
    console.log("Starting to fetch user data...");

    console.log("Token found:", token);

    if (token) {
      try {
        const response = await axios.get(API_ENDPOINT_GAUTH_LOGIN, {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true, // Include cookies in the request
        });

        console.log("Response received:", response);

        if (response.status) {
          console.log("User data successfully fetched:", response.data);
          await localStorage.setItem("token", response.data.data.accessToken);
          dispatch({
            type: SAVE_USER_INFO,
            payload: response.data.data.user_info,
          });
          dispatch({
            type: ADD_TOAST_MESSAGE,
            payload: { message: "Login successful", type: 1 },
          });
          // Cookies.remove("USER_TOKEN");
          navigate("/");
        } else {
          console.error("Unexpected response format:", response);
          throw new Error(response.message || "Login failed");
        }
      } catch (error) {
        console.error(
          "Error during user data fetch:",
          error.response ? error.response.data : error.message
        );
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: {
            message: error.message || "Error fetching user data",
            type: 0,
          },
        });
      } finally {
        setLoading(false);
      }
    } else {
      console.warn("No token found, skipping user data fetch.");
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("Component mounted, checking token...");
    handleGetUserData();
  }, [token]);

  if (loading) {
    console.log("Loading state:", loading);
    return <div>Loading...</div>; // Consider replacing with a spinner
  }

  console.log("Rendering children...");
  return <div>{children}</div>;
};

export default SocialAuth;

import "./App.css";
import { ConfigProvider } from "antd";
import { BrowserRouter as Router } from "react-router-dom";
import Entry from "Entry";
import { Provider } from "react-redux";
import configureStore from "store";
import Toast from "app/shared/Toast/Toast";
import { IntlProvider } from "react-intl";
import { FeatureFlagsProvider } from "app/utils/FeatureFlag/FeatrueFlag";
import locale from "antd/locale/en_US";
import SocialAuth from "SocialAuth";
import { NavigationProvider } from "app/utils/NavigationContext";

function App() {
  // console.log("routeconfig", routeConfig);
  return (
    <Provider store={configureStore}>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: "#000929",
            colorSecondary: "#E60023",
            fontFamily: "Futura",
          },
        }}
        locale={locale}
      >
        <IntlProvider locale="en" defaultLocale="en">
          <Router>
            <NavigationProvider>
              <SocialAuth>
                <Entry />
              </SocialAuth>
              <Toast />
            </NavigationProvider>
          </Router>
          <Toast />
        </IntlProvider>
        ,
      </ConfigProvider>
    </Provider>
  );
}

export default App;

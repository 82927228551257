import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { Navigate } from "react-router";
import Cookies from "js-cookie";

const ProtectedRoute = (props) => {
  const dispatch = useDispatch();

  // Check for token in cookies

  const isAuthenticated = localStorage.getItem("token");

  // Check if the user has the necessary access
  const hasAccess =
    props.accessKey && (props.userAccess || []).includes(props.accessKey);

  if (!isAuthenticated) {
    // User is not authenticated
    console.log("User is not authenticated");
    return <Navigate to="/auth/authenticate" />;
  }

  if (!hasAccess && props.accessKey) {
    // User does not have access
    console.log("User does not have access");

    dispatch({
      type: ADD_TOAST_MESSAGE,
      payload: {
        message:
          "You don't have access to this resource, redirecting back to the dashboard",
        type: 0,
      },
    });
    return <Navigate to="/" />;
  }

  // If authenticated and authorized, render the child components
  return props.children;
};

const mapStateToProps = (state) => ({
  userAccess: state.auth.userAccess,
});

export default connect(mapStateToProps, {})(ProtectedRoute);

import { InputNumber, Tag } from "antd";
import {
  API_ENDPOINT_PROPERTY_FETCH_SALES_CANCEL,
  API_ENDPOINT_PROPERTY_FETCH_SALES_POLICY_INFO,
} from "app/scenes/property/property.constants";
import { apiGet, apiPut } from "app/services/apiService";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Loader from "app/shared/Loader";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { parseURL } from "app/utils/helpers/common.helper";
import React, { useEffect, useState } from "react";
import { FormattedNumber } from "react-intl";
import { useDispatch } from "react-redux";

export default function PropertyCancel(props) {
  const [policyInfo, setPolicyInfo] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [reversePercentage, setReversePercentage] = useState(80);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.selectedProperty) getPolicyInfo();
  }, [props.selectedProperty]);
  const getPolicyInfo = async () => {
    setLoading(true);
    const response = await apiGet(
      parseURL(API_ENDPOINT_PROPERTY_FETCH_SALES_POLICY_INFO, {
        saleId: props.selectedProperty,
      })
    );
    if (response.status) {
      setPolicyInfo(response.data);
    }
    setLoading(false);
  };

  const cancelAnyway = async () => {
    const response = await apiPut(
      API_ENDPOINT_PROPERTY_FETCH_SALES_CANCEL + props.selectedProperty,
      {
        percentage: reversePercentage,
        amount:
          (parseFloat(policyInfo?.comssion?.amount) *
            parseInt(reversePercentage)) /
          100,
        level_one_earning:
          (parseFloat(policyInfo?.comssion?.level_one_earning) *
            parseInt(reversePercentage)) /
          100,
        level_two_earning:
          (parseFloat(policyInfo?.comssion?.level_two_earning) *
            parseInt(reversePercentage)) /
          100,
      }
    );
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onSuccess();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };
  return (
    <div className="">
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Typography variant="xs">
            Transaction will reverse when you cancel this sale. Please verify
            the details of transaction reversal. Broker's earned comission will
            have (80%) deduction and same for the following level one and two
            earners.
          </Typography>

          <div style={{ marginBottom: 24, marginTop: 24, width: 200 }}>
            <Typography variant="xs">Reversal Percentage (%)</Typography>

            <InputNumber
              placeholder={"Reversal Percentage (%)"}
              name={"reverse"}
              onChange={(num) => setReversePercentage(num)}
              //   defaultValue={}
              min={0}
              max={100}
              size="small"
              value={reversePercentage}
              style={{ width: 200 }}
            />
          </div>

          <div style={{ marginTop: 24, display: "flex", alignItems: "center" }}>
            <div>
              <Typography variant="xs">Property Sold By</Typography>
              <Typography>
                {policyInfo &&
                  policyInfo?.saleInfo?.sell_made_by?.user_profile
                    ?.first_name}{" "}
                {policyInfo &&
                  policyInfo?.saleInfo?.sell_made_by?.user_profile?.last_name}
              </Typography>
            </div>

            <div style={{ marginLeft: 24 }}>
              <Typography variant="xs">Comission Made</Typography>

              <Typography style={{ color: "red" }}>
                <FormattedNumber
                  value={(policyInfo && policyInfo?.comssion?.amount) || 0}
                  style="currency"
                  currency={"USD"}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </Typography>
            </div>

            <div style={{ marginLeft: 24 }}>
              <Typography variant="xs">Reversal Comission</Typography>

              <Typography style={{ color: "green" }}>
                <FormattedNumber
                  value={
                    (policyInfo &&
                      (parseFloat(policyInfo?.comssion?.amount) *
                        reversePercentage) /
                        100) ||
                    0
                  }
                  style="currency"
                  currency={"USD"}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </Typography>
            </div>
          </div>

          <div style={{ marginTop: 24, display: "flex", alignItems: "center" }}>
            <div>
              <Typography variant="xs">Level One Reverse</Typography>
              <Typography
                variant="xs"
                style={{ color: "red", textDecoration: "line-through" }}
              >
                <FormattedNumber
                  value={
                    (policyInfo && policyInfo?.comssion?.level_one_earning) || 0
                  }
                  style="currency"
                  currency={"USD"}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </Typography>
              <Typography style={{ color: "green" }}>
                <FormattedNumber
                  value={
                    (policyInfo &&
                      (parseFloat(policyInfo?.comssion?.level_one_earning) *
                        reversePercentage) /
                        100) ||
                    0
                  }
                  style="currency"
                  currency={"USD"}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </Typography>
            </div>

            <div style={{ marginLeft: 24 }}>
              <Typography variant="xs">Level Two Reverse</Typography>

              <Typography
                variant="xs"
                style={{ color: "red", textDecoration: "line-through" }}
              >
                <FormattedNumber
                  value={
                    (policyInfo && policyInfo?.comssion?.level_two_earning) || 0
                  }
                  style="currency"
                  currency={"USD"}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </Typography>
              <Typography style={{ color: "green" }}>
                <FormattedNumber
                  value={
                    (policyInfo &&
                      (parseFloat(policyInfo?.comssion?.level_two_earning) *
                        reversePercentage) /
                        100) ||
                    0
                  }
                  style="currency"
                  currency={"USD"}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginTop: 44,
            }}
          >
            <div style={{ width: 140, marginRight: 24 }}>
              <Button size="small" background="black" onClick={props.cancel}>
                Cancel
              </Button>
            </div>
            <div style={{ width: 140 }}>
              <Button size="small" onClick={cancelAnyway}>
                Proceed
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

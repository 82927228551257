import { BellFilled, MailFilled } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import { colorPicker } from "app/utils/helpers/color.helper";
import { useLocation, useNavigate } from "react-router";
import { styled } from "@mui/material/styles";
import { Avatar } from "@mui/material";
import Badge from "@mui/material/Badge";
import { Link } from "react-router-dom";
import AppLogo from "assets/images/appLogo.png";
import DummyAvatar from "assets/images/DummyAvatar.png";
import "./header.scss";
import Typography from "../Typography";
import { Padding } from "@mui/icons-material";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const Header = ({ userInfo }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/auth/authenticate");
  };

  const buttonStyle = {
    backgroundColor: "white",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  };

  // Menu items for the dropdown
  const menu = (
    <Menu>
      <Typography
        variant="caption"
        color="gray_text"
        style={{ Padding: "2px" }}
      >
        {userInfo && userInfo.email}
      </Typography>
      <Menu.Item key="1">
        <Link to="/">Profile</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/settings">Settings</Link>
      </Menu.Item>
      <Menu.Item key="3" onClick={handleLogout}>
        <Link to="#">Logout</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="Header">
      <div className="headerLeft">
        <div className="appLogoContainer">
          <Link to="/">
            <img
              src={AppLogo}
              alt="appLogo"
              className="profileImage"
              style={{ height: "2rem", paddingTop: "28px" }}
            />
          </Link>
        </div>
      </div>
      <div className="headerRight">
        <div className="rightItem">
          <Button
            type="primary"
            shape="circle"
            icon={<MailFilled style={{ color: colorPicker("gray_dark") }} />}
            style={buttonStyle}
            aria-label="Mail"
          />
        </div>
        <div className="rightItem">
          <Button
            type="primary"
            shape="circle"
            icon={<BellFilled style={{ color: colorPicker("gray_dark") }} />}
            style={buttonStyle}
            aria-label="Notifications"
          />
        </div>
        <div className="rightItem">
          <Dropdown overlay={menu} trigger={["click"]}>
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              <Avatar
                style={{ cursor: "pointer" }}
                alt="User Avatar"
                src={userInfo?.user_profile?.avatar || DummyAvatar}
              />
            </StyledBadge>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Header;

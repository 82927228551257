import { Form } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Typography from "app/shared/Typography";
import { useEffect, useState } from "react";
import "../../styles/onboarding.scss";

import { connect, useDispatch } from "react-redux";
import OTPBox from "app/shared/OTPBox";
import { apiPost, apiPut } from "app/services/apiService";
import {
  API_ENDPOINT_PROFILE_MOBILE_RESEND_OTP,
  API_ENDPOINT_PROFILE_MOBILE_VERIFY,
  API_ENDPOINT_PROFILE_UPDATE,
} from "../../onboard.constants";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { useNavigate } from "react-router";
import { colorPicker } from "app/utils/helpers/color.helper";

function OnbaordingProfile(props) {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [otp, setOTP] = useState("");
  const [counter, setCounter] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [selectedCode, setSelectedCode] = useState("+91");
  const [sceneTab, setSceneTab] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      // Must use callback form here; outer `counter` is in stale closure
      setCounter((counter) => (counter === 0 ? counter : counter - 1));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const updateAndVerify = async () => {
    const payload = {
      first_name: firstname,
      last_name: lastname,
      mobile: selectedCode + mobile,
    };

    if (payload.mobile && payload.mobile.length === 13) {
      const response = await apiPut(API_ENDPOINT_PROFILE_UPDATE, payload);
      if (response.status) {
        setSceneTab(1);
      }
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: "Invalid Mobile Number", type: 0 },
      });
    }
  };

  const resendOTP = async () => {
    setCounter(60);
    setLoading(true);
    const response = await apiPost(API_ENDPOINT_PROFILE_MOBILE_RESEND_OTP);
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  const verifyMobileNumber = async () => {
    const payload = {
      otp: otp,
    };

    if (payload.otp && payload.otp.length === 6) {
      const response = await apiPut(
        API_ENDPOINT_PROFILE_MOBILE_VERIFY,
        payload
      );
      if (response.status) {
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: response.message, type: 1 },
        });
        navigate("/onboard/profile_document");
      } else {
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: response.message, type: 0 },
        });
      }
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: "Enter Valid OTP", type: 0 },
      });
    }
    console.log(payload);
  };
  return sceneTab === 0 ? (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={updateAndVerify}
      // onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Typography style={{ textAlign: "center" }} variant="h5">
        Update Profile
      </Typography>
      <div className="input-element" style={{ marginBottom: 24 }}>
        <Form.Item
          name="firstname"
          initialValue={firstname}
          rules={[
            {
              required: true,
              message: "Please enter your first name",
            },
          ]}
        >
          <Input
            placeholder="First Name"
            label="First Name"
            onChange={(e) => setFirstname(e.target.value)}
            defaultValue={firstname}
          />
        </Form.Item>
      </div>
      <div className="input-element" style={{ marginBottom: 0 }}>
        <Form.Item
          name="lastname"
          rules={[
            {
              required: true,
              message: "Please enter your last name",
            },
          ]}
          initialValue={lastname}
        >
          <Input
            placeholder="Last Name"
            label="Last Name"
            onChange={(e) => setLastname(e.target.value)}
            defaultValue={lastname}
          />
        </Form.Item>
      </div>
      <div className="input-element" style={{ marginBottom: 24 }}>
        <Form.Item name="phone" style={{ fontSize: 12 }} initialValue={mobile}>
          <Input
            placeholder="Mobile"
            label="Mobile"
            type="phone"
            onChange={(data) => setMobile(data)}
            onCodeSelect={(data) => setSelectedCode(data)}
            defaultValue={mobile}
            maxLength={10}
          />
        </Form.Item>
      </div>

      <div className="action-buttons">
        <Form.Item style={{ width: "100%" }}>
          <Button
            type="primary"
            size="large"
            style={{ width: "100%" }}
            htmlType="submit"
            // onClick={updateAndVerify}
          >
            Update & Verify Mobile
          </Button>
        </Form.Item>
      </div>
    </Form>
  ) : (
    <>
      <Typography style={{ textAlign: "center" }} variant="h5">
        Verify Mobile
      </Typography>
      <Typography
        color="gray-dark"
        variant="body"
        style={{ marginBottom: 72, textAlign: "center", marginTop: 8 }}
      >
        We have send you an OTP to your provided mobile number{" "}
        <strong style={{ color: colorPicker("red"), marginTop: 2 }}>
          {selectedCode}
          {mobile}
        </strong>{" "}
        <small
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => setSceneTab(0)}
        >
          Wrong number?
        </small>
      </Typography>
      <div className="input-elements" style={{ marginBottom: 14 }}>
        <OTPBox onChange={(value) => setOTP(otp + value)} />
      </div>
      <Typography variant="caption" style={{ textAlign: "center" }}>
        Didn't receive OTP?{" "}
      </Typography>
      <Button
        size="large"
        style={{ width: "100%" }}
        type="link"
        danger
        disabled={counter > 0}
        onClick={resendOTP}
        loading={isLoading}
      >
        {counter === 0 ? "Send again" : `Send again in ${counter}`}
      </Button>
      <div className="action-buttons">
        <Button
          type="primary"
          size="large"
          style={{ width: "100%" }}
          onClick={verifyMobileNumber}
        >
          Verify
        </Button>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, {})(OnbaordingProfile);

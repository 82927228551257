import { Form } from "antd";
import { API_ENDPOINT_DASHBOARD_HUDDLE } from "app/scenes/dashboard/dashboard.constants";
import { apiGet, apiPost } from "app/services/apiService";
import Sharedbutton from "app/shared/Button";
import Input from "app/shared/InputBox";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { API_ENDPOINT_AUTH_LOGIN } from "../../auth.constants";
import { SAVE_USER_INFO } from "../../redux/auth.action";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";

import "../../styles/auth.scss";
import { Button, Stack } from "@mui/material";
import { Apple, Twitter } from "@mui/icons-material";
import Cookies from "js-cookie";
import { Icon } from "@iconify/react";

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPasword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);

    try {
      const payload = {
        email: email,
        password: password,
      };

      const response = await apiPost(API_ENDPOINT_AUTH_LOGIN, payload);

      console.log("API Response:", response); // Debugging line

      if (response.status) {
        console.log("Token Received:", response.data.accessToken); // Debugging line
        await localStorage.setItem("token", response.data.accessToken);
        dispatch({ type: SAVE_USER_INFO, payload: response.data.user_info });
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: "Login successful", type: 1 },
        });
        navigate("/");
      } else {
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: response.message || "Login failed", type: 0 },
        });
      }
    } catch (error) {
      console.error("Login error:", error); // Debugging line
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: "An error occurred during login", type: 0 },
      });
    } finally {
      setLoading(false);
    }
  };

  const Handlelogginwithgoogle = async () => {
    window.open(`${process.env.REACT_APP_API_URL}/auth/auth/google`, "_self");
  };

  const Handlelogginwithfacebook = () => {};

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleLogin}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="input-element" style={{ marginBottom: 24 }}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please check your email",
              },
            ]}
          >
            <Input
              placeholder="admin@neutonsoft.com"
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
              name="email"
            />
          </Form.Item>
        </div>
        <div className="input-element">
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter your password!",
                fontSize: 12,
              },
            ]}
            style={{ fontSize: 12 }}
          >
            <Input
              placeholder="123456"
              type="password"
              label="Password"
              onChange={(e) => setPasword(e.target.value)}
              name="password"
            />
          </Form.Item>

          <Typography
            color="primary_color"
            className="primary_color"
            variant="body"
            style={{ textAlign: "right", cursor: "pointer" }}
            onClick={() => navigate("/auth/forgot_password")}
          >
            Forgot Password?
          </Typography>
        </div>

        <div className="action-buttons">
          <Form.Item style={{ width: "100%" }}>
            <Sharedbutton
              type="primary"
              size="large"
              style={{ width: "100%" }}
              htmlType="submit"
              loading={isLoading}
            >
              Sign in
            </Sharedbutton>
          </Form.Item>
        </div>
      </Form>
      <Stack direction="row" spacing={1}>
        <Button
          onClick={Handlelogginwithgoogle}
          variant="contained"
          startIcon={
            <div
              style={{
                padding: "4px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "white",
              }}
            >
              <Icon icon="devicon:google" />
            </div>
          }
          sx={{ fontSize: "0.8rem", fontWeight: "600", background: "#5e89ef" }}
        >
          Login With Google
        </Button>
        <Button
          onClick={Handlelogginwithfacebook}
          variant="contained"
          startIcon={
            <div
              style={{
                padding: "4px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "white",
              }}
            >
              <Icon icon="logos:facebook" />
            </div>
          }
          sx={{ fontSize: "0.8rem", fontWeight: "600" }}
        >
          Login With Facebook
        </Button>
      </Stack>
      <Stack direction="row" spacing={1} style={{ marginTop: "4px" }}>
        <Button
          onClick={Handlelogginwithgoogle}
          variant="contained"
          startIcon={
            <Icon icon="mingcute:apple-fill" style={{ color: "white" }} />
          }
          sx={{ fontSize: "0.8rem", fontWeight: "600", background: "black" }}
        >
          Login With Apple
        </Button>
        <Button
          onClick={Handlelogginwithfacebook}
          variant="contained"
          startIcon={
            <Icon icon="mingcute:twitter-fill" style={{ color: "white" }} />
          }
          sx={{ fontSize: "0.8rem", fontWeight: "600", background: "#4fc3f7" }}
        >
          Login With Twitter
        </Button>
      </Stack>
      <Stack direction="row" spacing={1} style={{ marginTop: "4px" }}>
        <Button
          onClick={Handlelogginwithgoogle}
          variant="contained"
          startIcon={<Icon icon="fe:github" style={{ color: "white" }} />}
          sx={{ fontSize: "0.8rem", fontWeight: "600", background: "black" }}
        >
          Login With Github
        </Button>
        <Button
          onClick={Handlelogginwithfacebook}
          variant="contained"
          startIcon={<Icon icon="logos:microsoft-icon" />}
          sx={{ fontSize: "0.8rem", fontWeight: "600", background: "black" }}
        >
          Login With Microsoft
        </Button>
      </Stack>
    </>
  );
}

import { Form } from "antd";
import { apiGet, apiPost } from "app/services/apiService";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  API_ENDPOINT_AUTH_REGISTER,
  API_ENDPOINT_AUTH_VERIFY_INVITE,
} from "../../auth.constants";

import "../../styles/auth.scss";

export default function Register(props) {
  const [email, setEmail] = useState("");
  const [password, setPasword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  let { inviteId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (inviteId) fetchInviteInfo();
  }, []);
  const fetchInviteInfo = async () => {
    const response = await apiGet(API_ENDPOINT_AUTH_VERIFY_INVITE + inviteId);
    if (response.status) {
      setEmail(response?.data?.email);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  const handleRegister = async () => {
    setLoading(true);
    const payload = {
      email: email,
      password: password,
      password_confirm: confirmPassword,
    };

    if (password === confirmPassword) {
      const response = await apiPost(API_ENDPOINT_AUTH_REGISTER, payload);
      if (response.status) {
        navigate("/auth/signup_success", { state: { email: email } });
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: response.message, type: 1 },
        });
      } else {
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: response.message, type: 0 },
        });
      }
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: "Passwords do not match", type: 0 },
      });
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Form
        name="basic"
        initialValues={{ remember: true, ...{ email: email } }}
        onFinish={handleRegister}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        fields={[
          {
            name: ["email"],
            value: email,
          },
        ]}
      >
        {/* <Typography variant="h6" style={{ textAlign: "center" }}>
          Sign Up
        </Typography> */}
        <div className="input-element" style={{ marginBottom: 24 }}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please check your email",
              },
            ]}
            initialValue={email}
          >
            <Input
              placeholder="Email"
              type="email"
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
              defaultValue={email}
            />
          </Form.Item>
        </div>
        <div className="input-element" style={{ marginBottom: 0 }}>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter your confirm password!",
                fontSize: 12,
              },
            ]}
            style={{ fontSize: 12 }}
          >
            <Input
              placeholder="Password"
              type="password"
              label="Password"
              onChange={(e) => setPasword(e.target.value)}
            />
          </Form.Item>
        </div>
        <div className="input-element" style={{ marginBottom: 24 }}>
          <Form.Item
            name="password_confirm"
            rules={[
              {
                required: true,
                message: "Please enter your password!",
                fontSize: 12,
              },
            ]}
            style={{ fontSize: 12 }}
          >
            <Input
              placeholder="Confirm Password"
              type="password"
              label="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Form.Item>
        </div>

        <div className="action-buttons">
          <Form.Item style={{ width: "100%" }}>
            <Button
              type="primary"
              size="large"
              style={{ width: "100%" }}
              htmlType="submit"
              loading={isLoading}
            >
              Sign up
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}

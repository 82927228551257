import { Form, Select } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Typography from "app/shared/Typography";
import { useState } from "react";
import "../../styles/onboarding.scss";
import { connect, useDispatch } from "react-redux";
import { apiPut } from "app/services/apiService";
import { API_ENDPOINT_PROFILE_UPDATE_ROLE } from "../../onboard.constants";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { useNavigate } from "react-router";
const { Option } = Select;
function OnboardingRole(props) {
  const [roleName, setRoleName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleupdateRole = async () => {
    const payload = {
      role_id: roleName,
    };
    const response = await apiPut(API_ENDPOINT_PROFILE_UPDATE_ROLE, payload);
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.code, type: 1 },
      });
      navigate("/onboard/profile");
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.code, type: 0 },
      });
    }
    console.log("payload:", payload);
  };
  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={handleupdateRole}
      // onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Typography
        style={{ textAlign: "center", marginBottom: 64 }}
        variant="h5"
      >
        Select Your Role
      </Typography>

      <div className="input-element" style={{ marginBottom: 24 }}>
        <Form.Item
          name="roleName"
          rules={[
            {
              required: true,
              message: "Please select a role!",
            },
          ]}
          style={{ fontSize: 12 }}
        >
          <Select
            placeholder="Select Role"
            onChange={(value) => setRoleName(value)} // Update function to handle selected value
            style={{ fontSize: 12 }}
          >
            <Option value="03a91eff-e871-450e-82b4-a4771b02ca4f">BROKER</Option>
            <Option value="11788224-1e57-4a9d-ae54-21fcb3ddea20">
              INVESTOR
            </Option>
            <Option value="f4b2c3d8-77c2-45e9-a5f8-2e2bff50e8b3">AGENT</Option>
            <Option value="7d323c23-5f4f-4517-9a8f-bbabdd428a7d">
              EMPLOYEE
            </Option>
            <Option value="c540c947-9e48-46f7-b19e-111a7710f7d5">
              PROMOTER
            </Option>
            {/* Add more options as needed */}
          </Select>
        </Form.Item>
      </div>

      <div className="action-buttons">
        <Form.Item style={{ width: "100%" }}>
          <Button
            type="primary"
            size="large"
            style={{ width: "100%" }}
            htmlType="submit"
          >
            Update
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, {})(OnboardingRole);

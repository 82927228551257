import {
  API_ENDPOINT_PROJECT_PAYMENT_PLAN_INFO,
  API_ENDPOINT_PROPERTY_INFO,
} from "app/scenes/property/property.constants";
import { apiGet } from "app/services/apiService";
import Loader from "app/shared/Loader";
import MapBox from "app/shared/MapBox";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { parseURL } from "app/utils/helpers/common.helper";
import { useEffect, useState } from "react";
import { FormattedNumber } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import "./styles.scss";
export default function QuoatationView(props) {
  const [propertyInfo, setPropertyInfo] = useState(null);
  const [planInfo, setPlanInfo] = useState(null);
  const [isLoading, setLoading] = useState(true);
  let { propertyId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    fetchPropertyInfo();
  }, []);
  const fetchPropertyInfo = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_PROPERTY_INFO + propertyId);
    if (response.status) {
      setPropertyInfo(response.data);
      if (
        response.data &&
        response.data?.property_payment_plan?.plan_type !== "EMI"
      ) {
        fetchPaymentPlanInfo(
          response.data && response.data?.property_payment_plan?.custom_plan_id,
          response.data && response.data?.property_project?.id
        );
      } else {
        setLoading(false);
      }
    }
  };

  const fetchPaymentPlanInfo = async (planId, projectId) => {
    const response = await apiGet(
      parseURL(API_ENDPOINT_PROJECT_PAYMENT_PLAN_INFO, {
        projectId: projectId,
        planId: planId,
      })
    );
    if (response.status) {
      setPlanInfo(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  return isLoading ? (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Loader />
    </div>
  ) : (
    <page size="A4">
      <div className="topheader">
        <h3>SALES OFFER</h3>
        <img src="https://www.titosoftwares.com/images/logo-black.png" />
      </div>
      <p className="note">
        Thank you for your interest in Tito's Estate. Please find below the
        property details.
      </p>
      <div className="propertydetails">
        <h4>Property Details</h4>

        <div className="tabledetails">
          <table>
            <tr>
              <th>Project</th>
              <th>Property Type</th>
              <th>Unit</th>
              <th>Area Size ({propertyInfo?.size_unit})</th>
              <th>Selling Price</th>
            </tr>
            <tr>
              <td>{propertyInfo && propertyInfo?.property_project?.name}</td>
              <td>
                {(propertyInfo && propertyInfo?.property_type?.name) || "NA"}
              </td>
              <td>{(propertyInfo && propertyInfo?.unit) || "1"}</td>
              <td>{(propertyInfo && propertyInfo?.size) || "1"}</td>
              <td>{propertyInfo && propertyInfo?.cost}</td>
            </tr>
          </table>
        </div>
        <div style={{ marginTop: 12 }}>
          {/* <small>
            * Applicable fees to Dubai Land Department are 4% of the property
            net price + AED 5,139 Admin fee{" "}
          </small> */}
          <br />
          <small>
            * Prices and availability are subject to change without notice
          </small>
        </div>
      </div>
      {/* <div style={{ marginTop: 12, height: 320 }}>
        <MapBox
          defaultValue={{
            latitude: 28.6448,
            longitude: 77.216721,
          }}
        />
      </div> */}
      <div className="paymentdetails">
        {propertyInfo &&
        propertyInfo?.property_payment_plan?.plan_type === "EMI" ? (
          <div className="emidetails">
            <div className="boxcard">
              <h4>Downpayment (%)</h4>
              <h2>
                <FormattedNumber
                  value={propertyInfo?.property_payment_plan?.downpayment}
                  style="currency"
                  currency={"USD"}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </h2>
            </div>
            <div className="boxcard">
              <h4>Monthly EMI</h4>
              <h2>
                {" "}
                <FormattedNumber
                  value={propertyInfo?.property_payment_plan?.emi}
                  style="currency"
                  currency={"USD"}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </h2>
            </div>

            <div className="boxcard">
              <h4>EMI Duration</h4>
              <h2>{propertyInfo?.property_payment_plan?.plan_duration} Yr</h2>
            </div>

            <div className="boxcard">
              <h4>Interest Rate (%)</h4>
              <h2>{propertyInfo?.property_payment_plan?.interest}</h2>
            </div>
          </div>
        ) : (
          <div className="tabledetails">
            <h4>
              Custom Payment Plan prepared by{" "}
              {propertyInfo && propertyInfo?.property_project?.name} for this
              property.It comes with flexible mode of payment for users who do
              not want to go for bank loans.
            </h4>
            <table>
              <tr>
                <th>#</th>
                <th>Milestone</th>
                <th>%</th>
                <th>Date</th>
                <th>Amount (INR)</th>
              </tr>
              {planInfo &&
                planInfo?.property_payment_schemes_installments.map(
                  (plan, key) => {
                    return (
                      <tr>
                        <td>{plan.order}</td>
                        <td>{plan.name}</td>
                        <td>{plan.percentage}</td>
                        <td>{plan.day}</td>
                        <td>{plan.amount}</td>
                      </tr>
                    );
                  }
                )}
            </table>
          </div>
        )}
      </div>
    </page>
  );
}

import { DeleteOutlined, EditOutlined, InboxOutlined } from "@ant-design/icons";
import {
  Checkbox,
  DatePicker,
  Form,
  message,
  Modal,
  Popconfirm,
  Table,
  Tag,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  GLOBAL_TIME_FORMAT,
  PROJECT_STATUS_MAP,
  PROPERTY_TYPES,
} from "app/constants/global.constants";
import Input from "app/shared/InputBox";
import Selector from "app/shared/Selector";
import Typography from "app/shared/Typography";
import React, { useContext, useState } from "react";
import "./CreateProject.scss";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { colorPicker } from "app/utils/helpers/color.helper";
import STATE_LIST from "app/constants/states.json";
import COUNTRY_LIST from "app/constants/country.json";
import moment from "moment";
import {
  apiDelete,
  apiGet,
  apiPost,
  apiPostForm,
  apiPut,
} from "app/services/apiService";
import { useEffect } from "react";
import {
  API_ENDPOINT_PROJECT_ADDRESS_UPDATE,
  API_ENDPOINT_PROJECT_BULK_UPDATE_DOCUMENT,
  API_ENDPOINT_PROJECT_BULK_UPDATE_IMAGE,
  API_ENDPOINT_PROJECT_CREATE,
  API_ENDPOINT_PROJECT_DELETE_DOCUMENT,
  API_ENDPOINT_PROJECT_DELETE_IMAGE,
  API_ENDPOINT_PROJECT_INFO,
  API_ENDPOINT_PROJECT_UPDATE_INFO,
  API_ENDPOINT_PROPERTY_CREATE_UNIT,
  API_ENDPOINT_PROPERTY_FETCH_LIST_COMMON,
  API_ENDPOINT_PROPERTY_UPDATE_DOCUMENT,
  API_ENDPOINT_PROPERTY_UPDATE_IMAGE,
} from "../../property.constants";
import Button from "app/shared/Button";
import SearchSelector from "app/shared/SearchSelector";
import { parseURL } from "app/utils/helpers/common.helper";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Loader from "app/shared/Loader";
import dayjs from "dayjs";
import BulkPhotoUploader from "app/shared/BulkPhotoUploader";
import AddUnit from "../AddUnit";
import TableView from "app/shared/TableView";
import { FeatureFlags } from "app/utils/FeatureFlag/FeatrueFlag";
const { Dragger } = Upload;

const unitTableColumns = [
  {
    title: "Category",
    index: "property_type.property_category.name",
    key: "property_type.property_category.name",
  },
  {
    title: "Type",
    index: "property_type.name",
    key: "property_type.name",
  },
  {
    title: "BHK",
    render: (data) => {
      console.log("table data:?", data);
      return data?.property_type?.name === "Flat" && !data?.bedroom
        ? "Studio"
        : data?.property_type?.name === "Flat" ||
          data?.property_type?.name === "House/Villa"
        ? data?.bedroom + "BHK"
        : "NA";
    },
  },

  {
    title: "Total Unit",
    render: (data) => {
      return data?.property_units_lists?.length || 1;
    },
  },
];

export default function CreateProject(props) {
  const [imageList, setImageList] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [edited, setEdited] = useState(false);
  const [formData, setFormData] = useState({
    facilities: [
      {
        facility_id: "",
        distance: "",
      },
    ],
    investors: [
      {
        investor_id: "",
        amount: "",
        date: "",
      },
    ],
    property_types: [
      {
        property_type_id: "",
        block_name: "",
        floor: "",
        units: "",
      },
    ],
    country: "India",
    state: "Rajasthan",
    city: "Jaipur",
  });

  const { hasAccess } = useContext(FeatureFlags);

  const [cities, setCitites] = useState([]);
  const [amentities, setAmentities] = useState([]);
  const [projectInfo, setProjectInfo] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [operating, setOperating] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [unitList, setUnitList] = useState([]);
  const [backupImage, setBackupImages] = useState([]);
  const [newDocList, setNewDocList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { projectId } = useParams();

  useEffect(() => {
    fetchCity("Rajasthan");
    if (props.editMode) fetchProjectDetails();
  }, []);

  const actionColumnObj = {
    title: "Action",
    renderAction: true,
    handleAction: (propsData) => propsData.handleAction(propsData),
    actions: [
      {
        event: "edit",
        icon: <EditOutlined style={{ fontSize: 12, color: "white" }} />,
        color: "#f0932b",
        showOn: (data) => data.hasAccess,
        accessKey: "PROMOTER_MANAGE",
      },
      // {
      //   event: "delete",
      //   icon: <DeleteOutlined style={{ fontSize: 12, color: "white" }} />,
      //   color: "#FF0000",
      //   popup: true,
      //   popupTitle: "Are you sure want to delete?",
      //   showOn: (data) => data.hasAccess,
      //   accessKey: "PROMOTER_MANAGE",
      // },
    ],
  };

  const fetchProjectDetails = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_PROJECT_INFO + projectId);
    if (response.status) {
      setProjectInfo(response.data);
      handleInitialValues(response.data);
    } else {
      navigate("/property/projects");
    }
  };

  const fetchCity = async (initialCity = formData?.state) => {
    // You can await here
    const response = await apiPost(
      "https://countriesnow.space/api/v0.1/countries/state/cities",
      {
        country: "India",
        state: initialCity,
      }
    );
    if (!response.error) {
      let citiesFilter = response.data.map((city) => ({
        label: city,
        value: city,
      }));
      setCitites(JSON.parse(JSON.stringify(citiesFilter)));
      setFormData((prevFormData) => ({
        ...prevFormData,
        ["city"]: citiesFilter[0].value,
      }));
    }

    // ...
  };

  useEffect(() => {
    if (!formData.state) return;
    fetchCity();
  }, [formData.state]);
  const handleInitialValues = (respData) => {
    const getFacilityData = (facilities) =>
      facilities.map((item) => {
        return { facility_id: item?.facilite?.id, distance: item?.distance };
      });

    const getPropertyTypes = (property_types) =>
      property_types.map((item) => {
        return {
          property_type_id: item?.property_type?.id,
          block_name: item?.block_name,
          floor: item?.floors,
          units: item?.units,
        };
      });

    const getInvestorList = (investors) =>
      investors.map((item) => {
        return {
          investor_name:
            item?.investments?.user_profile?.first_name +
            " " +
            item?.investments?.user_profile?.last_name +
            " - " +
            item?.investments?.user_profile?.member_id,
          investor_id: item?.investorId,
          amount: item?.amount,
          date: item?.createdAt,
        };
      });
    let data = {
      name: respData?.name,
      status: respData?.status,
      starting_price: respData?.starting_price || 0,
      description: respData?.description,
      address_one: respData?.property_project_address?.address_one || "",
      address_two: respData?.property_project_address?.address_two || "",
      landmark: respData?.property_project_address?.landmark || "",
      state: respData?.property_project_address?.state || "",
      zipcode: respData?.property_project_address?.zip || "",
      city: respData?.property_project_address?.city || "",
      facilities: getFacilityData(respData?.property_project_facilites),
      investors: getInvestorList(respData?.investor_fundings),
      property_types: getPropertyTypes(respData?.property_project_unit_maps),
      amentities: respData?.project_amentities?.map((item) => item.amentityId),
      images: respData?.property_project_images,
      start_date: respData?.start_date,
      end_date: respData?.end_date,
      documents: respData?.property_project_documents,
      properties: respData?.properties,
      propertyCommunity: respData?.property_community?.id || null,
      propertyDeveloper: respData?.property_developer?.id || null,
    };
    setImageList(respData?.property_project_images || []);
    setDocumentList(respData?.property_project_documents || []);
    setFormData(JSON.parse(JSON.stringify(data)));
    setLoading(false);
  };

  useEffect(() => {
    fetchAmentities();
  }, []);
  const fetchAmentities = async () => {
    const response = await apiGet(
      API_ENDPOINT_PROPERTY_FETCH_LIST_COMMON + "?service=AMENTITIES"
    );
    if (response.status) {
      setAmentities(response.data);
    }
  };

  const createNewProject = async () => {
    setOperating(true);
    // return;
    const response = await apiPost(API_ENDPOINT_PROJECT_CREATE, formData);
    if (response.status) {
      updateProjectImages(response.data.id);
      createUnit(response.data.id);
    } else {
      setOperating(false);
    }
  };

  const updateProjectInfo = async () => {
    setOperating(true);

    // return;
    const response = await apiPut(
      API_ENDPOINT_PROJECT_UPDATE_INFO + projectId,
      formData
    );
    if (response.status) {
      updateProjectImages(projectId);
      setEdited(false);
    }
  };

  const updateProjectImages = async (newProjectId) => {
    let imageFormData = new FormData();
    console.log("property images", imageList);
    if (backupImage.length > 0) {
      for (const image of imageList) {
        imageFormData.append("file", image.originFileObj);
      }

      const response = await apiPostForm(
        parseURL(API_ENDPOINT_PROJECT_BULK_UPDATE_IMAGE, {
          projectId: newProjectId,
        }),
        imageFormData
      );
      if (response.status) {
        updateProjectDocument(newProjectId);
      } else {
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: response.message, type: 0 },
        });
      }
    } else {
      updateProjectDocument(newProjectId);
    }
  };

  const updateProjectDocument = async (newProjectId) => {
    let imageFormData = new FormData();
    if (newDocList.length > 0) {
      await Promise.all(
        documentList?.map(async (file) => {
          if (!file.url) imageFormData.append("file", file.originFileObj);
        })
      );
      const response = await apiPostForm(
        parseURL(API_ENDPOINT_PROJECT_BULK_UPDATE_DOCUMENT, {
          projectId: newProjectId,
        }),
        imageFormData
      );
      if (response.status) {
        updateProjectAddress(newProjectId);
      } else {
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: response.message, type: 0 },
        });
      }
    } else {
      updateProjectAddress(newProjectId);
    }
  };

  const updateProjectAddress = async (newProjectId) => {
    console.log("projectIdUpdatungaddress", newProjectId);
    const payload = {
      address_one: formData.address_one || null,
      address_two: formData.address_two || null,
      landmark: formData.landmark || null,
      city: formData.city,
      state: formData.state,
      zip: formData.zipcode,
      country: "India",
      projectId: newProjectId,
      latlong: formData.latlong,
    };

    const response = await apiPost(
      parseURL(API_ENDPOINT_PROJECT_ADDRESS_UPDATE, {
        projectId: newProjectId,
      }),
      payload
    );
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: {
          message: props.editMode ? "Project Updated" : "Project Created",
          type: 1,
        },
      });
      // navigate("/property/projects");
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setOperating(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("init handle change", name);

    if (value && name !== "status") {
      if (!edited) setEdited(true);
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleAmentitiyUpdate = (amentity) => {
    let newFormData = JSON.parse(JSON.stringify(formData));
    if (newFormData.amentities && newFormData.amentities.includes(amentity)) {
      newFormData.amentities = newFormData.amentities.filter(
        (item) => item !== amentity
      );
    } else {
      newFormData.amentities
        ? newFormData.amentities.push(amentity)
        : (newFormData.amentities = [amentity]);
    }
    setFormData(newFormData);
  };

  const handleAddProjectImage = (file) => {
    let newFormData = JSON.parse(JSON.stringify(formData));
    if (newFormData.images) newFormData.images.push(file);
    else newFormData["images"] = [file];

    setFormData(newFormData);
  };

  // const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const dragProps = {
    name: "file",
    // fileList: formData.images,
    listType: "picture-card",
    multiple: true,
    listType: "picture-card",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",

    // beforeUpload: handleAddProjectImage,
  };

  const handleNestedInputChange = (e, rowIndex, objectName) => {
    const { name, value } = e.target;
    let newFormData = JSON.parse(JSON.stringify(formData));
    newFormData[objectName][rowIndex][name] = value;
    // console.log("new form data:", newFormData);
    setFormData(newFormData);
  };

  const addMoreRowInForm = (objectName) => {
    let newFormData = JSON.parse(JSON.stringify(formData));
    let objectType = {
      facilities: {
        facility_id: "",
        distance: "",
      },
      investors: {
        investor_id: "",
        amount: "",
        date: "",
      },
      property_types: {
        property_type_id: "",
        block_name: "",
        floor: "",
        units: "",
      },
    };

    newFormData[objectName].push(objectType[objectName]);
    setFormData(newFormData);
  };

  const deleteImageFromProject = async (image) => {
    const response = await apiDelete(
      parseURL(API_ENDPOINT_PROJECT_DELETE_IMAGE, {
        projectId: projectId,
        imageId: image.id,
      })
    );
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  const deleteDocumentFromProject = async (doc) => {
    const response = await apiDelete(
      parseURL(API_ENDPOINT_PROJECT_DELETE_DOCUMENT, {
        projectId: projectId,
        documentId: doc.id,
      })
    );
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  // unit creation process

  const createUnit = async (newProjectId) => {
    // setLoading(true);
    for (let idx in unitList) {
      const payload = {
        ...unitList[idx],
      };
      console.log("payload is ", payload);

      const response = await apiPost(
        API_ENDPOINT_PROPERTY_CREATE_UNIT,
        payload
      );
      if (response.status) {
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: response.message, type: 1 },
        });
        updatePropertyDocument(
          response.data.id,
          unitList[idx].documents,
          unitList[idx].images
        );
      } else {
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: response.message, type: 0 },
        });
      }
    }
  };

  const updatePropertyDocument = async (
    newPropertyId,
    documentList,
    imageList
  ) => {
    if (documentList) {
      for (const doc_type of documentList) {
        try {
          let fileFormData = new FormData();
          console.log("uploading docs", doc_type.data);

          fileFormData.append("file", doc_type.data?.originFileObj);
          fileFormData.append("doc_type", doc_type.type);

          const response = await apiPostForm(
            parseURL(API_ENDPOINT_PROPERTY_UPDATE_DOCUMENT, {
              propertyId: newPropertyId,
            }),
            fileFormData
          );

          if (response.status) {
            addImageToUnit(newPropertyId, imageList);

            dispatch({
              type: ADD_TOAST_MESSAGE,
              payload: { message: response.message, type: 1 },
            });
          } else {
            dispatch({
              type: ADD_TOAST_MESSAGE,
              payload: { message: response.message, type: 0 },
            });
          }
        } catch (error) {
          console.error("Failed to upload document", error);
          // Dispatch an error message or handle the error
        }
      }
    }
  };

  const addImageToUnit = async (newPropertyId, imageList) => {
    for (const image of imageList) {
      // Create a new instance of FormData for each image
      let uploadFormData = new FormData();
      console.log("uploading image", image);
      uploadFormData.append("file", image); // Use the correct reference to images

      try {
        const response = await apiPostForm(
          parseURL(API_ENDPOINT_PROPERTY_UPDATE_IMAGE, {
            propertyId: newPropertyId,
          }),
          uploadFormData
        );
      } catch (error) {
        // Handle any errors that occur during the fetch call
        console.error("Error during image upload", error);
      }
    }
  };
  const handleDeleteUnit = async () => {};
  const handleTableAction = (event) => {
    console.log("event data is", event);
    switch (event.eventType) {
      case "edit":
        navigate(
          "/property/project/unit/edit/" +
            event.rowData.propertyProjectId +
            "/" +
            event?.rowData?.id
        );
      case "view_detail":
        return;
      default:
        return null;
    }
  };

  //end of unit creation process
  console.log("formDAta", formData);
  const handleAddUnitList = (unitData) => {
    setUnitList([...unitList, unitData]);
    setOpenModel(false);
    console.log("added unit data", unitData);
  };
  return isLoading ? (
    <Loader />
  ) : (
    <div className="CreateProject">
      <Form
        onFinish={props.editMode ? updateProjectInfo : createNewProject}
        initialValues={formData}
        autoComplete="off"
      >
        <div className="FormSection">
          <div className="form-row" style={{ marginBottom: 12 }}>
            <div className="input-element">
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please check your input",
                  },
                ]}
                // initialValue={formData.productPrice}
              >
                <Input
                  placeholder="Project Name"
                  label="Project Name"
                  background="white"
                  onChange={handleInputChange}
                  borderColor="black"
                  name="name"
                  defaultValue={formData.name}
                />
              </Form.Item>
            </div>
            <div className="input-element">
              <Form.Item name="status">
                <Typography
                  variant="body"
                  weight={400}
                  style={{ marginLeft: 4, marginBottom: 4 }}
                >
                  Status
                </Typography>
                <Selector
                  placeholder="Status"
                  labelKey="name"
                  valueKey="id"
                  serviceType="STATIC"
                  onChange={handleInputChange}
                  name="status"
                  fullObject
                  staticData={PROJECT_STATUS_MAP}
                  autoSelect="BUILDING"
                />
              </Form.Item>
            </div>
            <div className="input-element">
              <Form.Item
                name="starting_price"
                rules={[
                  {
                    required: true,
                    message: "Please check your input",
                  },
                ]}
                // initialValue={formData.productPrice}
              >
                <Input
                  placeholder="Starting Price"
                  label="Starting Price"
                  background="white"
                  onChange={handleInputChange}
                  borderColor="black"
                  name="starting_price"
                  defaultValue={formData.starting_price}
                  prefix={
                    <AttachMoneyIcon
                      style={{ fontSize: 16, color: colorPicker("gray_dark") }}
                    />
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="form-row" style={{ marginBottom: 12 }}>
            <div style={{ width: "100%" }}>
              <Form.Item
                name="description"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please check your input",
                //   },
                // ]}
                initialValue={formData.description}
              >
                <Typography variant="body" style={{ marginBottom: 4 }}>
                  Description
                </Typography>
                <TextArea
                  //   value={value}
                  //   onChange={(e) => setValue(e.target.value)}
                  placeholder="Description here...."
                  style={{
                    backgroundColor: "white",
                    width: "100%",
                    // border: "black",
                    borderRadius: 12,
                  }}
                  onChange={(e) =>
                    handleInputChange({
                      target: { name: "description", value: e.target.value },
                    })
                  }
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  defaultValue={formData.description}
                  name="description"
                />
              </Form.Item>
            </div>
          </div>

          <div className="form-row" style={{ marginBottom: 12 }}>
            <div className="input-element">
              <Form.Item
                name="address_one"
                rules={[
                  {
                    required: true,
                    message: "Please check your input",
                  },
                ]}
                // initialValue={formData.productPrice}
              >
                <Input
                  placeholder="Address One"
                  label="Address One"
                  background="white"
                  onChange={handleInputChange}
                  borderColor="black"
                  name="address_one"
                  defaultValue={formData.address_one}
                />
              </Form.Item>
            </div>
            <div className="input-element">
              <Form.Item name="address_two">
                <Input
                  placeholder="Address Two"
                  label="Address Two"
                  background="white"
                  onChange={handleInputChange}
                  borderColor="black"
                  name="address_two"
                  defaultValue={formData.address_two}
                />
              </Form.Item>
            </div>
            <div className="input-element">
              <Form.Item
                name="landmark"
                rules={[
                  {
                    required: true,
                    message: "Please check your input",
                  },
                ]}
                // initialValue={formData.productPrice}
              >
                <Input
                  placeholder="Landmark"
                  label="Landmark"
                  background="white"
                  onChange={handleInputChange}
                  borderColor="black"
                  name="landmark"
                  defaultValue={formData.landmark}
                />
              </Form.Item>
            </div>
          </div>
          <div className="form-row" style={{ marginBottom: 12 }}>
            <div className="input-element" style={{ width: "23%" }}>
              <Form.Item
                name="country"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please check your input",
                //   },
                // ]}
                // initialValue={formData.productPrice}
              >
                <Typography variant="body" style={{ marginBottom: 4 }}>
                  Country
                </Typography>

                <SearchSelector
                  height={50}
                  placeholder="Country"
                  staticData={COUNTRY_LIST}
                  serviceType="STATIC"
                  labelKey="label"
                  valueKey="value"
                  onChange={(e) =>
                    handleInputChange({
                      target: { name: "country", value: e },
                    })
                  }
                  defaultValue={formData.country || "India"}
                />
              </Form.Item>
            </div>
            <div className="input-element" style={{ width: "23%" }}>
              <Form.Item
                name="state"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please check your input",
                //   },
                // ]}
                // initialValue={formData.productPrice}
              >
                <Typography variant="body" style={{ marginBottom: 4 }}>
                  State
                </Typography>

                <SearchSelector
                  height={50}
                  placeholder="State"
                  staticData={STATE_LIST}
                  serviceType="STATIC"
                  labelKey="label"
                  valueKey="value"
                  onChange={(e) =>
                    handleInputChange({
                      target: { name: "state", value: e },
                    })
                  }
                  defaultValue={formData.state || "Rajasthan"}
                />
              </Form.Item>
            </div>
            <div className="input-element" style={{ width: "23%" }}>
              <Form.Item name="city">
                <Typography variant="body" style={{ marginBottom: 4 }}>
                  City
                </Typography>

                <SearchSelector
                  height={50}
                  placeholder="City"
                  staticData={cities}
                  serviceType="STATIC"
                  labelKey="label"
                  valueKey="value"
                  onChange={(e) =>
                    handleInputChange({
                      target: { name: "city", value: e },
                    })
                  }
                  defaultValue={formData.city || "Jaipur"}
                />
              </Form.Item>
            </div>
            <div className="input-element" style={{ width: "23%" }}>
              <Form.Item
                name="zipcode"
                rules={[
                  {
                    required: true,
                    message: "Please check your input",
                  },
                ]}
                // initialValue={formData.productPrice}
              >
                <Input
                  placeholder="Zipcode"
                  label="Zipcode"
                  background="white"
                  onChange={handleInputChange}
                  borderColor="black"
                  name="zipcode"
                  defaultValue={formData.zipcode}
                />
              </Form.Item>
            </div>
          </div>

          <div className="form-row" style={{ marginBottom: 12 }}>
            <div className="input-element" style={{ width: "23%" }}>
              <Form.Item name="propertyDeveloper">
                <Typography
                  variant="body"
                  weight={400}
                  style={{ marginLeft: 4, marginBottom: 4 }}
                >
                  Property Developer
                </Typography>
                <Selector
                  placeholder="Property Developer"
                  labelKey="name"
                  valueKey="id"
                  serviceType="PROPERTY_DEVELOPERS"
                  onChange={handleInputChange}
                  name="propertyDeveloper"
                  defaultValue={formData.propertyDeveloper}
                />
              </Form.Item>
            </div>

            <div className="input-element" style={{ width: "23%" }}>
              <Form.Item name="propertyCommunity">
                <Typography
                  variant="body"
                  weight={400}
                  style={{ marginLeft: 4, marginBottom: 4 }}
                >
                  Property Community
                </Typography>
                <Selector
                  placeholder=" Property Community"
                  labelKey="name"
                  valueKey="id"
                  serviceType="PROPERTY_COMMUNITY"
                  onChange={handleInputChange}
                  name="propertyCommunity"
                  defaultValue={formData.propertyCommunity}
                />
              </Form.Item>
            </div>
            <div className="input-element" style={{ width: "23%" }}>
              <Form.Item
                name="start_date"

                // initialValue={formData.productPrice}
              >
                <Typography variant="body" style={{ marginBottom: 4 }}>
                  Start Date
                </Typography>
                <DatePicker
                  // onChange={(ojb, dateString) =>
                  //   setToDate(moment(dateString).format(GLOBAL_TIME_FORMAT))
                  // }
                  defaultValue={dayjs(
                    formData.start_date || moment().format("YYYY-MM-DD"),
                    "YYYY-MM-DD"
                  )}
                  onChange={(ojb, dateString) =>
                    handleInputChange({
                      target: {
                        name: "start_date",
                        value: moment(dateString)
                          .endOf("day")
                          .format(GLOBAL_TIME_FORMAT),
                      },
                    })
                  }
                  name="start_date"
                  style={{
                    height: 50,
                    minWidth: 200,
                    borderColor: "black",
                  }}
                />
              </Form.Item>
            </div>
            <div className="input-element" style={{ width: "23%" }}>
              <Form.Item
                name="end_date"

                // initialValue={formData.productPrice}
              >
                <Typography variant="body" style={{ marginBottom: 4 }}>
                  End Date
                </Typography>
                <DatePicker
                  // onChange={(ojb, dateString) =>
                  //   setToDate(moment(dateString).format(GLOBAL_TIME_FORMAT))
                  // }
                  defaultValue={dayjs(
                    formData.end_date || moment().format("YYYY-MM-DD"),
                    "YYYY-MM-DD"
                  )}
                  onChange={(ojb, dateString) =>
                    handleInputChange({
                      target: {
                        name: "end_date",
                        value: moment(dateString)
                          .endOf("day")
                          .format(GLOBAL_TIME_FORMAT),
                      },
                    })
                  }
                  name="end_date"
                  style={{
                    height: 50,
                    minWidth: 200,
                    borderColor: "black",
                  }}
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="FormSection">
          <div className="Title">
            <Typography variant="body" style={{ marginBottom: 4 }}>
              Amentities
            </Typography>
          </div>

          <div className="form-row" style={{ marginBottom: 12 }}>
            <div className="full-row-element" style={{ width: "100%" }}>
              <div className="ItemContainer">
                {amentities.map((amentity, key) => {
                  return (
                    <div className="Item">
                      <Checkbox
                        onChange={() => handleAmentitiyUpdate(amentity?.id)}
                        checked={formData?.amentities?.includes(amentity?.id)}
                      >
                        {amentity?.name}
                      </Checkbox>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="FormSection">
          <div className="Title">
            <Typography>Project Images</Typography>
          </div>
          <div>
            {/* <Dragger
              {...dragProps}
              // fileList={[]}
              height={200}
              // onChange={handleChange}
              // onRemove={deleteImageFromProperty}
              // customRequest={addImageToProperty}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">Maximum file size upload: 10 MB</p>
            </Dragger> */}
            <Dragger
              {...dragProps}
              fileList={imageList || []}
              height={200}
              beforeUpload={(file, list) => {
                return false;
              }}
              onChange={({ fileList: newFileList }) => {
                setImageList(newFileList);
                setBackupImages(newFileList);
              }}
              onRemove={deleteImageFromProject}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">Maximum file size upload: 10 MB</p>
            </Dragger>
          </div>
        </div>

        <div className="FormSection">
          <div className="Title">
            <Typography>Project Documents</Typography>
          </div>
          <div>
            <Dragger
              {...dragProps}
              fileList={documentList || []}
              height={200}
              beforeUpload={(file, list) => {
                return false;
              }}
              onChange={({ fileList: newFileList }) => {
                setDocumentList(newFileList);
                setNewDocList(newFileList);
              }}
              onRemove={deleteDocumentFromProject}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">Maximum file size upload: 10 MB</p>
            </Dragger>
          </div>
        </div>

        <div className="FormSection">
          <div className="Title">
            <Typography variant="body" style={{ marginBottom: 4 }}>
              Distance key between facilities
            </Typography>
          </div>

          <div className="form-row" style={{ marginBottom: 12 }}>
            <div className="ItemVerticleContainer">
              {formData?.facilities &&
                formData?.facilities?.map((facility, key) => {
                  return (
                    <div className="ItemContainer">
                      <div className="ItemWidget">
                        <Form.Item name={key + "_property_type"}>
                          <Selector
                            placeholder="Facility"
                            labelKey="name"
                            valueKey="id"
                            serviceType="FACILITIES"
                            onChange={(e) =>
                              handleNestedInputChange(e, key, "facilities")
                            }
                            name={"facility_id"}
                            fullObject
                            defaultValue={facility.facility_id}
                          />
                        </Form.Item>
                      </div>
                      <div className="ItemWidget">
                        <Form.Item
                          name={key + "_distance"}

                          // initialValue={formData.productPrice}
                        >
                          <Input
                            placeholder="Distance"
                            background="white"
                            onChange={(e) =>
                              handleNestedInputChange(e, key, "facilities")
                            }
                            borderColor="black"
                            name={"distance"}
                            defaultValue={facility.distance}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  );
                })}

              <Tag
                style={{ cursor: "pointer" }}
                onClick={() => addMoreRowInForm("facilities")}
              >
                Add More
              </Tag>
            </div>
          </div>
        </div>
        {props.editMode ? (
          <div
            className="FormSection"
            style={{ flexDirection: "column", display: "flex" }}
          >
            <div className="Title">
              <Typography variant="body" style={{ marginBottom: 4 }}>
                Project Units
              </Typography>
            </div>

            <div
              className="form-row"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <TableView
                column={[...unitTableColumns, actionColumnObj]}
                data={formData?.properties || []}
                style={{ width: "100%" }}
                hasAccess={hasAccess}
                handleAction={handleTableAction}
              />
              {/* <div
                className="ItemVerticleContainer"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {formData.properties &&
                  formData.properties?.map((property, key) => {
                    return (
                      <div
                        className="ItemContainer"
                        kye={key}
                        style={{ marginRight: 20, width: "auto" }}
                      >
                        <div className="CardListContainer">
                          <div className="CardListInfo">
                            {property?.property_type?.name} -
                            <Tag color="blue" style={{ marginLeft: 6 }}>
                              {property?.property_units_lists?.length || 1}{" "}
                              units
                            </Tag>
                            <EditOutlined
                              style={{
                                margin: "0 8px",
                                color: "orange",
                                fontSize: 18,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                navigate(
                                  "/property/project/unit/edit/" +
                                    projectId +
                                    "/" +
                                    property?.id
                                )
                              }
                            />
                            <Popconfirm
                              title="Delete Project Unit"
                              description="Are you sure to delete this unit?"
                              onConfirm={null}
                              onCancel={null}
                              okText="Yes"
                              cancelText="No"
                            >
                              <DeleteOutlined
                                style={{
                                  margin: "0 8px",
                                  color: "red",
                                  fontSize: 18,
                                  cursor: "pointer",
                                }}
                              />
                            </Popconfirm>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div> */}

              <Tag
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (edited) {
                    dispatch({
                      type: ADD_TOAST_MESSAGE,
                      payload: {
                        message: "Please save project info ",
                        type: 0,
                      },
                    });
                  } else if (
                    projectInfo?.property_payment_schemes?.length === 0
                  ) {
                    dispatch({
                      type: ADD_TOAST_MESSAGE,
                      payload: {
                        message:
                          "Please create payment plan before adding unit ",
                        type: 0,
                      },
                    });
                  } else {
                    navigate("/property/project/unit/create/" + projectId, {
                      state: { projectName: formData?.name },
                    });
                  }
                }}
              >
                Add Unit
              </Tag>
            </div>
          </div>
        ) : null}

        <div className="FormSection">
          <div className="Title">
            <Typography variant="body" style={{ marginBottom: 4 }}>
              Project Investors
            </Typography>
          </div>

          <div className="form-row" style={{ marginBottom: 12 }}>
            <div className="ItemVerticleContainer">
              {formData?.investors &&
                formData?.investors?.map((investor, key) => {
                  return (
                    <div className="ItemContainer" key={key}>
                      <div className="ItemWidget">
                        <Form.Item
                          name={key + "_invesotr_id"}

                          // initialValue={formData.productPrice}
                        >
                          <SearchSelector
                            height={50}
                            placeholder="Investor"
                            serviceType="USERS"
                            labelKey="fullname"
                            valueKey="userId"
                            onChange={(e) =>
                              handleNestedInputChange(
                                { target: { name: "investor_id", value: e } },
                                key,
                                "investors"
                              )
                            }
                            defaultValue={investor.investor_name}
                            name="investor_id"
                          />
                        </Form.Item>
                      </div>
                      <div className="ItemWidget">
                        <Form.Item
                          name={key + "_amount"}

                          // initialValue={formData.productPrice}
                        >
                          <Input
                            placeholder="Amount"
                            background="white"
                            // onChange={handleInputChange}
                            onChange={(e) =>
                              handleNestedInputChange(e, key, "investors")
                            }
                            borderColor="black"
                            name="amount"
                            defaultValue={investor.amount}
                          />
                        </Form.Item>
                      </div>
                      <div className="ItemWidget">
                        <Form.Item
                          name={key + "_invest_date"}

                          // initialValue={formData.productPrice}
                        >
                          <DatePicker
                            // onChange={(ojb, dateString) =>
                            //   setToDate(moment(dateString).format(GLOBAL_TIME_FORMAT))
                            // }
                            defaultValue={dayjs(
                              investor.date || moment().format("YYYY-MM-DD"),
                              "YYYY-MM-DD"
                            )}
                            onChange={(ojb, dateString) =>
                              handleNestedInputChange(
                                {
                                  target: {
                                    name: "date",
                                    value:
                                      moment(dateString).format(
                                        GLOBAL_TIME_FORMAT
                                      ),
                                  },
                                },
                                key,
                                "investors"
                              )
                            }
                            name="toDate"
                            style={{
                              height: 50,
                              minWidth: 200,
                              borderColor: "black",
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  );
                })}

              <Tag
                style={{ cursor: "pointer" }}
                onClick={() => addMoreRowInForm("investors")}
              >
                Add More
              </Tag>
            </div>
          </div>
        </div>
        <div className="FormAction" style={{ marginTop: 24 }}>
          {props.editMode ? (
            <div style={{ width: 240, marginRight: 24 }}>
              <Button
                background="black"
                onClick={() => navigate("/property/projects")}
              >
                Cancel
              </Button>
            </div>
          ) : null}
          <div style={{ width: 240 }}>
            <Button htmlType="submit" loading={operating}>
              {props.editMode ? "Save" : "Create"}
            </Button>
          </div>
        </div>
      </Form>
      <Modal
        title={`Add Unit (${formData.name})`}
        open={openModel}
        onCancel={() => setOpenModel(false)}
        footer={false}
        width={1200}
      >
        <AddUnit onAdd={handleAddUnitList} />
      </Modal>
    </div>
  );
}
